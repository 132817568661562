import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { CrudForm, HtmlHelpers, OptionsCountry } from '@westernmilling/eu_react_shared';

import Locations from 'components/Options/Locations';

export default withAuth0(withTranslation()(withAlert()(class ShipToField extends CrudForm {
  constructor(props) {
    super(props);
    this.state.addNewLoc = false;
  }

  openAddNewLoc = () => this.setState({ addNewLoc: true });

  formType = () => this.props.t('pageTitles.locations');

  onChange = (event) => this.props.onChange(event);

  getItem = () => this.props.item;

  getLabelColumns = () => 3;

  getFieldColumns = () => 7;

  buildQueryParams = () => {
    const params = {
      tms_locations: 'Y', with_systems: 'Y', show_all: 'true', sorted: JSON.stringify([{ id: 'name', desc: false }]),
    };
    if (HtmlHelpers.isNotEmpty(this.props.entityUuid)) {
      params.entity_uuid = this.props.entityUuid;
    }
    return params;
  }

  options = () => (
    {
      country: {
        component: OptionsCountry,
        field: 'new_loc_country',
        label: this.props.t('fields.country'),
        currentValue: this.getItem().new_loc_country,
        props: { isRequired: true },
      },
      locations: {
        component: Locations,
        field: 'ship_to_location_uuid',
        label: this.props.t('fields.shipTo'),
        currentValue: this.getItem().ship_to_location_uuid,
        disabled: this.state.addNewLoc,
        buttonAfter: this.getButton(this.props.t('buttons.shipToAdd'), this.openAddNewLoc),
        props: { apiParams: this.buildQueryParams(), isRequired: this.props.isRequired || false },
      },
    }
  );

  streetAddr = () => this.getItem().new_loc_street_address;

  secondLine = () => this.getItem().new_loc_additional_address_line;

  addrRow = (field, label, value, req = true) => (
    this.getTextFieldRow(field, label, req, { value })
  );

  locationFields = () => (
    this.state.addNewLoc ? (
      <div>
        {this.addrRow('new_loc_name', this.props.t('fields.shipToName'), this.getItem().new_loc_name)}
        {this.addrRow('new_loc_street_address', this.props.t('fields.address1'), this.streetAddr())}
        {this.addrRow('new_loc_additional_address_line', this.props.t('fields.address2'), this.secondLine(), false)}
        {this.addrRow('new_loc_city', this.props.t('fields.city'), this.getItem().new_loc_city)}
        {this.addrRow('new_loc_region', this.props.t('fields.province'), this.getItem().new_loc_region)}
        {this.addrRow('new_loc_region_code', this.props.t('fields.postalCode'), this.getItem().new_loc_region_code)}
        {this.dropdownField('country')}
      </div>
    ) : ''
  );

  getContents = () => (
    <div>
      {this.dropdownField('locations')}
      {this.locationFields()}
    </div>
  )
})));
