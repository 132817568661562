import React from 'react';
import { withAlert } from 'react-alert';
import { withTranslation } from 'react-i18next';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import CarrierField from 'components/Forms/CarrierField';
import CarrierAgencies from 'components/Options/CarrierAgencies';
import ContractItems from 'components/Options/ContractItems';
import ShipToField from 'components/Forms/ShipToField';
import TimeSlots from 'components/Options/TimeSlots';

export default withTranslation()(withAlert()(class TruckOrderForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.formKey = 1;
  }

  formType = () => this.props.t('pageTitles.truckOrder');

  getCrudType = () => 'contracts/tms_truck_orders';

  successAlertMsg = () => this.successMsg(this.props.t('pageTitles.truckOrder'));

  getLabelColumns = () => 3;

  getFieldColumns = () => 7;

  getModalSize = () => 'xl';

  timeSlotDisabled = () => (
    HtmlHelpers.isEmpty(this.getItem().pickup_date) || !['Available', 'Scheduled'].includes(this.getItem().status)
  );

  options = () => (
    {
      carrierAgency: {
        component: CarrierAgencies,
        field: 'carrier_agency_entity_uuid',
      },
      contractItems: {
        component: ContractItems,
        field: 'item_uuid',
        label: 'Item',
        props: {
          valueField: 'item_uuid',
          isRequired: true,
          apiParams: {
            query: `contract_uuid = '${this.getItem().contract_uuid}'`,
            order: 'reference',
          },
        },
      },
      timeSlot: {
        component: TimeSlots,
        field: 'time_slot_uuid',
        currentValue: this.getItem().time_slot_uuid,
        disabled: this.timeSlotDisabled(),
        props: {
          isRequired: HtmlHelpers.isNotEmpty(this.getItem().pickup_date),
          key: this.state.formKey,
          orderParams: {
            order_uuid: this.getItem().uuid,
            item_uuid: this.getItem().item_uuid,
            pickup_date: this.getItem().pickup_date,
          },
        },
      },
    }
  );

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';

    let formKey = this.state.formKey;
    if (name === 'pickup_date') {
      newData.time_slot_uuid = '';
      formKey += 1;
    }

    this.setVariables({ item: newData, formKey });
  }

  carrierField = () => (
    <CarrierField
      auth={this.props.auth}
      entityUuid={this.getItem().carrier_entity_uuid}
      item={this.getItem()}
      onChange={this.onChange}
    />
  );

  hasContract = () => HtmlHelpers.isNotEmpty(this.getItem().contract_id);

  getItemField = () => (this.hasContract() ? this.dropdownField('contractItems') : '');

  additionalFields = () => {
    let display = '';
    if (this.getItem().is_customer) {
      display = (
        <div>
          {this.getTextFieldRow('po_number', this.props.t('fields.customerPo'), false)}
          {this.dropdownField('carrierAgency')}
          {this.carrierField()}
          {this.getItemField()}
        </div>
      );
    } else if (this.getItem().is_carrier_agency) {
      display = (
        <div>
          {this.carrierField()}
        </div>
      );
    }
    return display;
  };

  shipToDisplay = () => (
    HtmlHelpers.isEmpty(this.getItem().transfer_to_name) ? (
      <ShipToField
        auth={this.props.auth}
        entityUuid={this.getItem().bill_to_entity_uuid}
        item={this.getItem()}
        onChange={this.onChange}
        isRequired={HtmlHelpers.isNotEmpty(this.getItem().time_slot_uuid)}
      />
    ) : ''
  );

  modalBodyRows = () => (
    <div>
      {this.additionalFields()}
      {this.getDateFieldRow('pickup_date', this.props.t('fields.pickupDate'), false)}
      {this.dropdownField('timeSlot')}
      {this.getTextFieldRow('driver_name', this.props.t('fields.driver'), false)}
      {this.getTextFieldRow('driver_id', this.props.t('fields.driverId'), false)}
      {this.getTextFieldRow('driver_cell_phone', this.props.t('fields.driverCellPhone'), false)}
      {this.getTextFieldRow('truck_plate', this.props.t('fields.truckPlate'), false)}
      {this.getTextFieldRow('trailer_plate', this.props.t('fields.trailerPlate'), false)}
      {this.shipToDisplay()}
    </div>
  )
}));
